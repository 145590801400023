@import url("https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jua&display=swap");

body {
  margin: 0;
  font-family: "NanumSquareNeo-Variable", "Pretendard Variable", "Gothic A1",
    "Jua", "Do Hyeon", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  --background: #ebebeb;
  --main-text: #1c1c1c;
  --sub-text: #747474;
  --accent-color: #6bc676;
  --main-ui: #b3b3b3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "NanumSquareNeo-Variable";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

.hidden {
  overflow: hidden !important;
}

div {
  -webkit-tap-highlight-color: transparent;
}

.disabled {
  cursor: default;
}
